import React from 'react';

import { Spring } from 'react-spring';
import Layout from '../layouts/en';
import SEO from '../components/seo';
import MainSection from '../styles/MainSection';

const CoilLogin = props => (
  <Layout {...props}>
    <SEO title="Login with Coil successful" />
    <MainSection>
      <Spring from={{ opacity: 0, bottom: -40 }} to={{ opacity: 1, bottom: 0 }}>
        {springProps => (
          <div className="container" style={springProps}>
            <h1>Login with Coil successful.</h1>
            <p>Thanks for supporting awesome creators!</p>
          </div>
        )}
      </Spring>
    </MainSection>
  </Layout>
);

export default CoilLogin;
